import Button from "./Button";
import Success from "../../src/assets/images/success.png";

export default function FormSuccessfulMessage({ text, onclick, imgstyle, pstyle }) {
  return (
    <div>
      <div className="success-img">
        <img src={Success} alt="Successful" style = {imgstyle} />
      </div>
      <p style = {pstyle}>{text}</p>
      <Button text="Home" className="btn2Style continue" onclick={onclick} />
    </div>
  );
}
