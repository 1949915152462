import React, { useState, useEffect, useMemo } from "react";
// import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TabbedHistogramWithAPI = () => {
  // State for the active tab and chart data
  const [activeTab, setActiveTab] = useState("Daily");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  // Use useMemo to memoize the tabToFilter mapping
  const tabToFilter = useMemo(
    () => ({
      Daily: "day",
      Weekly: "week",
      Monthly: "month",
      Yearly: "year",
    }),
    []
  );

  // When you uncomment the useEffect below, go to AdminLogin component and remove the useCallBack fetching FinanceRep data

  // useEffect(() => {
  //   const storedAdminId = localStorage.getItem("adminId");
  //   const fetchFinanceRep = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await axios.get(
  //         "https://9jadelivery.com/femaletechMobile/public/api/adminFinanceRep",
  //         {
  //           params: {
  //             id: storedAdminId,
  //             filter: tabToFilter[activeTab], // Use the mapped filter value
  //           },
  //         }
  //       );
  //       const data = response.data;
  //       const labels = Object.keys(data);
  //       const values = labels.map((key) => data[key].revenue); // Assuming "revenue" is the key for the dataset

  //       // Update chart data
  //       setChartData({
  //         labels,
  //         datasets: [
  //           {
  //             label: "",
  //             data: values,
  //             backgroundColor: "#FFFFFF",
  //             borderColor: "#32200433",
  //             color: "#FFFFFF",
  //             borderWidth: 1,
  //           },
  //         ],
  //       });
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     }
  //   };
  //   fetchFinanceRep();
  // }, [activeTab, tabToFilter]);

  useEffect(() => {
    const histogramDataString = localStorage.getItem("histogramData");
    const data = JSON.parse(histogramDataString);
    if (data) {
      const labels = Object.keys(data);
      const values = labels.map((key) => data[key].revenue); // Assuming "revenue" is the key for the dataset

      // Update chart data
      setChartData({
        labels,
        datasets: [
          {
            label: "",
            data: values,
            backgroundColor: "#FFFFFF",
            borderColor: "#32200433",
            color: "#FFFFFF",
            borderWidth: 1,
          },
        ],
      });
    }
  }, []);


  return (
    <div style={{ width: "80%" }}>
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(tabToFilter).map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            style={{
              margin: "0 10px",
              padding: "5px",
              // backgroundColor: activeTab === tab ? "#C30532" : "#f0f0f0",
              color: "#322004",
              // border: "none",
              borderBottom: activeTab === tab ? "2px solid #C30532" : "#FFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Bar Chart */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ height: "170px", width: "100%", maxWidth: "1200px", }}>
              <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false }, // Completely remove the legend
            },
            scales: {
              x: {
                ticks: {
                  color: "white", // Set X-axis tick color to white
                },
              },
              y: {
                ticks: {
                  color: "white", // Set Y-axis tick color to white
                },
                grid: {
                  color: "#C30532",
                },
                beginAtZero: true, // Ensure Y-axis starts at zero
              },
            },
          }}
          style={{
            backgroundColor: "#C30532", // Chart background color
            borderRadius: "6px", // Rounded corners for the chart container
            padding: "16px",
            height: "400px",
          }}
          datasetIdKey="id"
          datasets={[
            {
              borderRadius: 15, 
              borderSkipped: false,
            },
          ]}
        />
        </div>
    
      )}
    </div>
  );
};

export default TabbedHistogramWithAPI;
