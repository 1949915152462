// src/components/Logout.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ({setAdminDetails, setFinanceBudget}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the token from localStorage
    localStorage.clear();
    setAdminDetails("");
    setFinanceBudget("");

    // Redirect to login page
    navigate("/");
  }, [navigate, setAdminDetails, setFinanceBudget]);

  return null;
};

export default Logout;
