import React, { useState } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const CouponGenerator = ({  setMenu2Active, isLoading, setIsLoading }) => {
  const [couponCode, setCouponCode] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateCoupon = () => {
    const newCode = generateRandomCode();
    setCouponCode(newCode);
  };

  const handleInputChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createCoupon",
        {
          couponCode: couponCode,
          email: "Bukky@femaletechpreneur.com",
          canCreateCoupon: true,
        }
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
        setFormSubmitted(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }

    setTimeout(() => {
      setErrorMessage("");
    }, 2000);
  };

  const handleFormHomeBtn = () => {
    setFormSubmitted(false);
    setMenu2Active(1);
    setCouponCode("");
  };

  return (
    <form
      onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}
      className="programme-form"
      style={{ margin: "3rem", width: "80%" }}
    >
      {!formSubmitted ? (
        <>
          <h1 style={{ fontSize: "2.2rem" }}> Enter Coupon Code: </h1>
          <div>
            <input
              type="text"
              value={couponCode}
              name="couponCode"
              onChange={handleInputChange}
              placeholder="Enter Coupon Code"
              style={{
                margin: "4rem 0 2rem",
                width: "100%",
                padding: "1.4rem",
              }}
              required
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                type="button"
                onClick={handleGenerateCoupon}
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                  color: "#525252",
                  width: "25%",
                }}
                className="continue btn1Style"
              >
                Generate Coupon
              </button>
              <button
                type="submit"
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                  width: "20%",
                }}
                className="continue btn2Style"
              >
                Publish
              </button>
            </div>

            {errorMessage ? <ErrorMessage message={errorMessage} /> : ""}
          </div>
        </>
      ) : (
        <FormSuccessfulMessage
          text={`Coupon Code: ${couponCode} created successfully`}
          imgstyle={{ marginLeft: "4rem" }}
          pstyle={{ marginLeft: "2.8rem" }}
          onclick={handleFormHomeBtn}
        />
      )}
    </form>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default CouponGenerator;
